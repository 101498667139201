import React from "react";

import { graphql } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types";

import DetailPageHeader from "../../components/DetailPageHeader/DetailPageHeader";
import QuickFacts from "../../components/QuickFacts/QuickFacts";
import QuickFactsItem from "../../components/QuickFactsItem/QuickFactsItem";
import Sharing from "../../components/Sharing/Sharing";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const PublicationPage = ({ data: { page } }) => (
  <MainLayout breadcrumb={<Breadcrumb id={page.id} />}>
    <DetailPageHeader
      headline={page.title}
      image={page.images && page.images.length > 0 && page.images[0]?.asset && (
        <SanityImage
          {...page.images[0]}
          width={800}
          className="w-full"
        />
      )}
      sharing={(
        <Sharing
          shareTitle={page.title}
          title="Diese Publikation teilen:"
        />
        )}
      type={page.title}
    />
    <div className="grid grid-cols-12 mx-18">
      <div className="col-span-12 order-2 lg:order-1 lg:col-span-9">
        <div className="grid grid-cols-12 lg:grid-cols-9">
          <div className="col-span-12 lg:col-span-7 mt-18 lg:mt-0 lg:col-start-2 text-h3-mobile lg:text-h3-desktop uppercase">
            Über die Pubikation
          </div>
          <div className="col-span-12 lg:col-span-9 text-body1-mobile lg:text-body1-desktop mt-24 lg:mt-42">
            {page.description}
          </div>
        </div>
      </div>
      <div className="col-span-12 order-1 lg:order-2 lg:col-span-3">
        <QuickFacts>
          <QuickFactsItem title="Autor:innen">
            {page.authors}
          </QuickFactsItem>
          <QuickFactsItem title="Erscheinungsjahr">
            {page.year}
          </QuickFactsItem>
          <QuickFactsItem title="Umfang">
            {page.scope}
          </QuickFactsItem>
          <QuickFactsItem title="Herausgeber:innen">
            {page.publisher}
          </QuickFactsItem>
          <QuickFactsItem title="Verlag">
            {page.publishing_house}
          </QuickFactsItem>
          <QuickFactsItem title="ISBN">
            {page.isbn}
          </QuickFactsItem>
        </QuickFacts>
      </div>
    </div>
  </MainLayout>
);
// -f891f5df-8c2d-553f-8c9d-9b97107baaec
export const query = graphql`
  query ($id: String!) {
    page: sanityPublication(id: {eq: $id}) {
      _id
      id
      isbn
      publisher
      publishing_house
      scope
      slug {
        current
      }
      images {
        ...ImageWithPreview
        alt
      }
      title
      year
      authors
      description
      Metadata {
        ...MetaData
      }
    }
  }
`;

PublicationPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default PublicationPage;
